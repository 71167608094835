import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import './TimeFormField.scss'
import { useEffect, useState } from 'react'
import { Checkbox } from 'components'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import moment from 'moment'

interface Props {
  value: moment.Moment | null;
  onChange: (newValue: moment.Moment) => void;
  label: string;
  disabled?: boolean;
  required?: boolean;
}

export default function TimeFormField({
  value,
  onChange,
  label,
  disabled,
  required,
}: Props) {
  const [timeDelta, setTimeDelta] = useState<number>(1)
  const [activeTimeDelta, setActiveTimeDelta] = useState(false)
  const handleTimeChange = (newValue: moment.Moment) => {
    const valueHours = newValue.hours()
    const valueMinutes = newValue.minutes()

    let newTime = moment().hours(valueHours).minutes(valueMinutes)
    if (activeTimeDelta) {
      newTime = newTime.add(timeDelta, 'days')
    }

    onChange(newTime)
  }

  const handleAddTimeDelta = () => {
    setTimeDelta(prev => Math.min(prev + 1, 3))
  }

  const handleSubtractTimeDelta = () => {
    setTimeDelta(prev => Math.max(prev - 1, 1))
  }

  useEffect(() => {
    if (value) {
      handleTimeChange(value)
    }
  }, [activeTimeDelta, timeDelta])

  return (
    <div className="time-form-field">
      <span>
        {label}
        {required && <span style={{ color: '#DA4238' }}>*</span>}
      </span>
      <span />
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <TimePicker
          ampm={false}
          value={value}
          onChange={handleTimeChange}
          disabled={disabled}
        />
      </LocalizationProvider>
      <div className="time-delta">
        <Checkbox
          checked={activeTimeDelta}
          onChange={() => setActiveTimeDelta(!activeTimeDelta)}
          label={`J+${timeDelta}`}
        />
        <div className="time-delta-buttons">
          <button type="button" onClick={handleAddTimeDelta}>
            <KeyboardArrowUp />
          </button>
          <button type="button" onClick={handleSubtractTimeDelta}>
            <KeyboardArrowDown />
          </button>
        </div>
      </div>
    </div>
  )
}
