import { Box } from '@mui/material'
import { CheckboxFormField, SelectFormField, TextFormField, TimeFormField } from 'components'
import terms from 'assets/terms'
import { useAppSelector, useCerbereUsers } from 'utils'
import { FormValues } from '../formHandlers'

interface Props {
  formValues: FormValues
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement | { name?: string | undefined; value: unknown }>) => void
  handleCheckboxChange: (name: string) => () => void
  handleTimeChange: (name: string, value: moment.Moment) => void
  handleMultiSelectChange: (e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => void
  technicalCenterId: string
  requiredFields: string[]
}

const localTerms = terms.Modals.AddMissionTC
export default function MissionTCFields({
  formValues, handleInputChange, handleCheckboxChange, handleTimeChange, handleMultiSelectChange,
  technicalCenterId, requiredFields,
}: Props) {
  const { agents } = useAppSelector(state => state.missionTable)
  const { missions, technicalCenters, additionalMissions } = useAppSelector(state => state.params)
  const { getUserName } = useCerbereUsers(agents.map(agent => agent.cerbere_id))

  const selectedChantierDepart = technicalCenters.find(tc => tc.id === technicalCenterId)?.chantiers.find(
    chantier => chantier.id === formValues.chantier_depart,
  )

  const selectedChantierArrivee = technicalCenters.find(tc => tc.id === technicalCenterId)?.chantiers.find(
    chantier => chantier.id === formValues.chantier_arrivee,
  )

  return (
    <div className="fields">
      {/* Rame */}
      <Box display="flex" flexDirection="column" gap="4px" marginBottom="-22px">
        <TextFormField
          value={formValues.rame}
          onChange={handleInputChange}
          label={localTerms.rame}
          required
          name="rame"
        />
        <CheckboxFormField
          value={formValues.um}
          onChange={handleCheckboxChange('um')}
          name={localTerms.um}
        />
      </Box>
      {/* Matériel attelé */}
      <TextFormField
        value={formValues.um_details}
        onChange={handleInputChange}
        label={localTerms.umDetails}
        disabled={!formValues.um}
        name="um_details"
      />
      {/* Mission */}
      <SelectFormField
        value={formValues.modele}
        onChange={handleInputChange}
        label={localTerms.mission}
        name="modele"
        options={missions.map(mission => ({ id: mission.id, libelle: mission.libelle }))}
        required
      />
      {/* Non visible pour le RD */}
      <CheckboxFormField
        value={formValues.prioritaire}
        onChange={handleCheckboxChange('prioritaire')}
        label={localTerms.rdHidden}
        name={localTerms.urgent}
      />
      {/* Chantier départ */}
      <SelectFormField
        value={formValues.chantier_depart}
        onChange={handleInputChange}
        label={localTerms.chantier_depart}
        name="chantier_depart"
        options={technicalCenters.find(tc => tc.id === technicalCenterId)?.chantiers.map(
          chantier => ({ id: chantier.id, libelle: chantier.libelle }),
        )}
        required={requiredFields.includes('chantier_depart')}
        disabled={!requiredFields.includes('chantier_depart')}
      />
      {/* Chantier arrivée */}
      <SelectFormField
        value={formValues.chantier_arrivee}
        onChange={handleInputChange}
        label={localTerms.chantier_arrivee}
        name="chantier_arrivee"
        options={technicalCenters.find(tc => tc.id === technicalCenterId)?.chantiers.map(
          chantier => ({ id: chantier.id, libelle: chantier.libelle }),
        )}
        required={requiredFields.includes('chantier_arrivee')}
        disabled={!requiredFields.includes('chantier_arrivee')}
      />
      {/* Voie de départ */}
      <SelectFormField
        value={formValues.voie_depart}
        onChange={handleInputChange}
        label={localTerms.voie_depart}
        name="voie_depart"
        options={selectedChantierDepart?.voies.map(voie => ({ id: voie.id, libelle: voie.libelle }))}
        required={requiredFields.includes('voie_depart')}
        disabled={!requiredFields.includes('voie_depart')}
      />
      {/* Voie d‘arrivée */}
      <SelectFormField
        value={formValues.voie_arrivee}
        onChange={handleInputChange}
        label={localTerms.voie_arrivee}
        name="voie_arrivee"
        options={selectedChantierArrivee?.voies.map(voie => ({ id: voie.id, libelle: voie.libelle }))}
        required={requiredFields.includes('voie_arrivee')}
        disabled={!requiredFields.includes('voie_arrivee')}
      />
      {/* Heure début théorique */}
      <TimeFormField
        value={formValues.heure_debut_theorique}
        onChange={value => handleTimeChange('heure_debut_theorique', value)}
        label={localTerms.heure_debut_theorique}
        required
        disabled={formValues.modele === ''}
      />
      {/* Heure fin théorique */}
      <TimeFormField
        value={formValues.heure_fin_theorique}
        onChange={value => handleTimeChange('heure_fin_theorique', value)}
        label={localTerms.heure_fin_theorique}
        required
        disabled={formValues.modele === ''}
      />
      {/* Agent */}
      <SelectFormField
        value={formValues.agent}
        onChange={handleInputChange}
        label={localTerms.agent}
        name="agent"
        options={agents.map(agent => ({ id: agent.cerbere_id,
          libelle: getUserName(agent.cerbere_id) }))}
        required
        disabled={formValues.modele === ''}
      />
      {/* Sens de départ */}
      <SelectFormField
        value={formValues.sens_depart}
        onChange={handleInputChange}
        label={localTerms.sens_depart}
        name="sens_depart"
        options={technicalCenters.find(tc => tc.id === technicalCenterId)?.sens_depart.map(
          depart => ({ id: depart.id, libelle: depart.libelle }),
        )}
        required={requiredFields.includes('sens_depart')}
        disabled={!requiredFields.includes('sens_depart')}
      />
      {/* Via */}
      <TextFormField
        value={formValues.via}
        onChange={handleInputChange}
        label={localTerms.via}
        required={requiredFields.includes('via')}
        name="via"
        disabled={!requiredFields.includes('via')}
      />
      {/* Missions supplémentaires */}
      <SelectFormField
        value={formValues.missions_supplementaires}
        onChange={handleMultiSelectChange}
        label={localTerms.missions_supplementaires}
        name="missions_supplementaires"
        multiple
        options={additionalMissions.map(additional => ({ id: additional.id, libelle: additional.libelle }))}
        disabled={formValues.modele === ''}
      />
      {/* Observation COM */}
      <TextFormField
        value={formValues.observation_com}
        onChange={handleInputChange}
        label={localTerms.observation_com}
        name="observation_com"
        disabled={formValues.modele === ''}
      />
      {/* Pancartage */}
      <TextFormField
        value={formValues.pancartage}
        onChange={handleInputChange}
        label={localTerms.pancartage}
        name="pancartage"
        disabled={formValues.modele === ''}
      />
    </div>
  )
}
